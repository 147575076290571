.footer-main {
  position: relative;
  /* margin-top: 12em; */
  background-color: var(--black);
  color: var(--white);
}

.footer-logo a img {
  width: 250px;
  height: 50px;
  cursor: pointer;
}
.footer-mid {
  width: fit-content;
  margin-right: 50px;
}

.footer-right {
  margin-left: 50px;
}
.send-cta {
  display: flex;
  justify-content: end;
}
/* Responsive */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
}

@media only screen and (max-width: 767px) {
  .footer-main {
    margin-top: -100px;
  }
  .footer-main .footer-content {
    row-gap: 40px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .footer-right {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 500px) {
}

@media only screen and (max-width: 479px) {
}
