.faqsection{
    margin-bottom: 40px;
}

.faqsection .faqsection-content{
    column-gap: 56px;  
  }

.faqsection .faq-image{
    width: auto;
}

.faqsection .faq-image img{
    width: 100%;
    height:400px;
    object-fit: cover;
}

@media only screen and (max-width: 426px) {
    .faqsection .faq-image img{
        width: 380px;   
    }
    .faqsection .faqsection-content{
      row-gap: 40px;  
    }
}

@media only screen and (max-width: 376px) {
    .faqsection .faq-image img{
        width: 320px;
        height:320px;
        
    }
}